import alert from 'o365.controls.alert.ts';
import { userSession } from 'o365.modules.configs.ts';

const deprecatedFileName = 'o365.controls.alert.js';
const replacementFileName = 'o365.controls.alert.ts';

console.warn(`This file is deprecated (${deprecatedFileName}). Use ${replacementFileName}`);

if (userSession.isDeveloper || window.location.host === 'omega.omega365.com') {
    const message = userSession.isDeveloper
        ? `Please note that this app is currently using a deprecated file (${deprecatedFileName}).\n\nPlease update all imports from '${deprecatedFileName}' to '${replacementFileName}' as soon as possible.`
        : `Please note that this app is currently using a deprecated file.\nThe app will still work as intented.\n\nPlease report this issue to get it resolved! (Check for duplicate WFs before reporting)`;

    alert(message, userSession.isDeveloper ? 'warning' : 'info', {
        autohide: true,
        delay: 10 * 1000,
        slimVersion: true
    });
}

export default alert;
